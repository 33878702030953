<template>
  <vx-card>
    <div class="vx-col sm:w-1/1 w-full mb-base">
      <vs-tabs>
        <vs-tab label="ST MAP Export Report">
          <div class="tab-text">
            <div class="vx-col">
              <div class="vx-row mb-6 w-full">
                <div class="vx-col w-1/5">
                  <div class="vx-col w-full flex items-center">
                    <span>Warehouse</span>
                  </div>
                  <div class="vx-col w-full">
                    <multiselect
                      class="selectExample"
                      v-model="selectedTerritories"
                      :options="optionTerritories"
                      :multiple="true"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="120"
                      :limit="3"
                      placeholder="Type to search"
                      :custom-label="customLabel"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-col w-1/5">
                  <div class="vx-col w-full flex items-center">
                    <span>Principal</span>
                  </div>
                  <div class="vx-col w-full">
                    <multiselect
                      class="selectExample"
                      v-model="selectedPrincipal"
                      :options="optionPrincipal"
                      :multiple="true"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="120"
                      :limit="3"
                      placeholder="Type to search"
                      :custom-label="customLabel"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-col w-1/5">
                  <div class="vx-col w-full flex items-center">
                    <span>Item</span>
                  </div>
                  <div class="vx-col w-full">
                    <multiselect
                      class="selectExample"
                      v-model="selectedItem"
                      :options="optionItem"
                      :multiple="true"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="120"
                      :limit="3"
                      placeholder="Type to search"
                      :custom-label="customLabel"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-col w-1/5">
                  <div class="vx-col w-full flex items-center">
                    <span>Posting Date Start</span>
                  </div>
                  <div class="vx-col w-full flex items-center gap-4">
                    <datepicker
                      ref="picker"
                      opens="center"
                      :singleDatePicker="true"
                      :timePicker="false"
                      :timePicker24Hour="false"
                      :showWeekNumbers="false"
                      :showDropdowns="false"
                      :autoApply="true"
                      v-model="openKeyDate"
                      class="w-full"
                    >
                      <template v-slot:input="postingDate" style="width: 100%">
                        {{ dateFormat(postingDate.startDate) }}
                      </template>
                    </datepicker>
                    <vs-button color="danger" @click="clearPostingDate()"
                      >X</vs-button
                    >
                  </div>
                </div>
                <div class="vx-col w-1/5">
                  <div class="vx-col w-full flex items-center">
                    <span>Posting Date</span>
                  </div>
                  <div class="vx-col w-full flex items-center gap-4">
                    <datepicker
                      ref="picker"
                      opens="center"
                      :singleDatePicker="true"
                      :timePicker="false"
                      :timePicker24Hour="false"
                      :showWeekNumbers="false"
                      :showDropdowns="false"
                      :autoApply="true"
                      v-model="openKeyDate"
                      class="w-full"
                    >
                      <template v-slot:input="postingDate" style="width: 100%">
                        {{ dateFormat(postingDate.endDate) }}
                      </template>
                    </datepicker>
                    <vs-button color="danger" @click="clearPostingDate()"
                      >X</vs-button
                    >
                  </div>
                </div>
              </div>

              <div class="vx-row w-fit">
                <div class="vx-col w-full flex items-center"></div>
                <div class="vx-col w-full">
                  <vs-button color="success" @click="handleDrawTable()"
                    >Get Data</vs-button
                  >
                </div>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <data-table
                  :baseUrl="this.baseUrl"
                  :territoryIDs="this.territoryIDs"
                  :principalIDs="this.principalIDs"
                  :itemIDs="this.itemIDs"
                  :territoryNames="this.territoryNames"
                  :draw="this.draw"
                  :startPostingDate="this.postingDate.startDate"
                  :endPostingDate="this.postingDate.endDate"
                ></data-table>
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <data-table-export></data-table-export>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import DataTable from './DataTable.vue';
import DataTableExport from './DataTableExport.vue';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';

export default {
  components: {
    DataTable,
    DataTableExport,
    DateRangePicker,
    Datepicker,
  },
  data() {
    return {
      baseUrl: '/api/report/v1/territories',
      selectedTerritories: [],
      optionTerritories: [],
      selectedPrincipal: [],
      optionPrincipal: [],
      selectedItem: [],
      optionItem: [],
      territoryIDs: [],
      principalIDs: [],
      itemIDs: [],
      territoryCodes: [],
      territoryNames: [],
      draw: 0,
      postingDate: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    customLabel(val) {
      if (val) {
        return val.code === '-' ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
    handleClose() {
      this.detail = false;
    },

    handleOpen() {
      this.detail = true;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format('dddd, MMMM Do YYYY');
      }
      return a;
    },
    getTerritories() {
      this.$http.get('/api/report/v1/master/territory').then((resp) => {
        this.optionTerritories = resp.data.records;
      });
    },
    handleDrawTable() {
      this.draw++;
    },
  },
  mounted() {
    this.getTerritories();
    this.draw++;
  },
  watch: {
    selectedTerritories(val) {
      let territoryIDs = [];
      let territoryCodes = [];
      let territoryNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          territoryIDs.push(e.id);
          territoryCodes.push(e.code);
          territoryNames.push(e.name);
        }
      });

      this.territoryIDs = territoryIDs;
      this.territoryCodes = territoryCodes;
      this.territoryNames = territoryNames;
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
